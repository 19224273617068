@import "@/assets/scss/style.scss";

.help-page {
    margin-left: 1rem;
    &__img {
        height: 30px;
        cursor: pointer;
        color: $roltek-blue;
    }

    &__phone-number {
        color: $roltek-blue;
    }

    &__slide {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding-bottom: 5rem;
    }

    &__slide-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 600px) {
            justify-content: center;
            flex-direction: column;
        }
    }

    &__slide-img {
        height: 500px;
        margin-right: 2rem;

        @media screen and (max-width: 600px) {
            margin: 0 0 2rem 0;
        }
    }

    &__slide-info {
        font-size: 1.2rem;
        color: #101010;
        font-weight: bold;
    }

    &__slide-number {
        font-size: 2rem;
        position: absolute;
        bottom: 0;
    }

    &__paging-img {
        height: 50px;
        margin-right: 1rem;
    }

    &__left-button {
        margin: -3rem 0rem 0rem 0.35rem;
    }

    &__done-button {
        margin: -3rem -0.5rem 0rem 0;
    }

    &__popover-content {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        a:nth-child(2) {
            margin: 0.3rem 0;
        }
    }
}

.slide-0 {
    flex-direction: column;
}
.slide-0 .help-page__slide-img {
    height: 270px;
    margin: 0 0 8rem 0;
}

.ant-dropdown-menu::after {
    display: none;
}

.ant-carousel {
    padding: 0 2rem;
}

.ant-carousel {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    background: #ffffff;
    border-radius: 6px;
}

.ant-carousel .carousel-arrow {
    font-size: 35px;
    color: $roltek-blue;
    width: auto;
    z-index: 1;
    top: 98.5%;
}
.ant-carousel .carousel-arrow:before {
    display: none;
}
.ant-carousel .carousel-arrow:hover {
    opacity: 0.85;
}

::v-deep .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
}

::v-deep .slick-dots {
    display: none !important;
}

::v-deep .slick-slide {
    margin-top: 1rem;
}

::v-deep .slick-prev {
    left: -40px;
}

::v-deep .slick-next {
    right: -23px;
}

.instruction-step {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-last {
    opacity: 1 !important;
}

::v-deep .ant-modal-footer {
    display: none;
}

.desktop {
    cursor: auto;
    -webkit-user-drag: none;
}

.hidePopover {
    display: none;
}
.project-key{
    font-weight: bold;
}


.projectInfoPopUp{
    display:flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    
}
.confirmProjectInfo{
    display:flex;
    width:100%;
    padding-top:10px;
    flex-direction: row;
    justify-content: space-between;
    align-content: spa;
    border-top: 1px solid rgba(0, 0, 0, 0.123);
}

